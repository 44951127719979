<template>
  <section id="join-event" class="route-content">
    <loader v-if="loading" />

    <form
      class="passcode-modal"
      v-else-if="!startMeeting"
      @submit.prevent="attemptStartMeeting"
    >
      <event-subheader :event="event" />

      <hr class="divider" />

      <label class="h6" for="passcode"> Enter meeting passcode: </label>
      <div class="inputs">
        <input
          type="text"
          class="grow"
          name="passcode"
          v-model="passcode"
          placeholder="Enter passcode"
        />
        <input
          type="submit"
          class="button outline"
          :value="submitPasscodeText"
        />
      </div>
    </form>

    <zoom
      v-else
      :event-id="event.id"
      :meeting-id="event.zoomMeetingId"
      :meeting-role="meetingRole"
      :passcode="passcode"
      type="Event"
    />

    <p v-if="errorMessage" class="error--text">{{ errorMessage }}</p>
  </section>
</template>

<script>
import { getEventById } from "../models/events";
import Loader from "../components/Loader.vue";
import Zoom from "./Zoom.vue";
import EventSubheader from "../components/EventSubheader.vue";
import PermissionsMixin from "../components/mixins/permissions.mixin";

export default {
  name: "JoinEvent",

  components: { Zoom, Loader, EventSubheader },

  mixins: [PermissionsMixin],

  data: () => ({
    errorMessage: null,
    event: null,
    loading: true,
    passcode: null,
    startMeeting: false
  }),

  computed: {
    $zoomElements() {
      const $body = document.body;
      return [
        $body.querySelector("#zmmtg-root"),
        $body.querySelector("#aria-notify-area")
      ];
    },

    meetingRole() {
      return Number(this.isAssigned(this.event));
    },

    submitPasscodeText() {
      return this.meetingRole === 1 ? "Start Meeting!" : "Join Meeting!";
    }
  },

  beforeDestroy() {
    this.hideZoomElements();
  },

  async mounted() {
    this.hideZoomElements();

    const { eventId } = this.$route.params;
    if (eventId) {
      try {
        this.loading = true;
        this.event = await getEventById(eventId);
        this.loading = false;
      } catch (error) {
        this.errorMessage = error.message || error;
        this.loading = false;
      }
    }
  },

  methods: {
    attemptStartMeeting() {
      this.startMeeting = true;
      this.showZoomElements();
    },

    hideZoomElements() {
      this.$zoomElements.forEach($elem => {
        if ($elem) $elem.classList.add("hide");
      });
    },

    showZoomElements() {
      this.$zoomElements.forEach($elem => {
        if ($elem) $elem.classList.remove("hide");
      });
    }
  }
};
</script>

<style lang="scss">
.passcode-modal {
  height: 400px;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: $md;
  max-width: 800px;
  width: $parent;
  z-index: 100;

  .inputs {
    @include flex;
    max-width: 24rem;
  }
}
</style>
