<template>
  <section v-if="errorMessage" id="zoom-container" class="iframe-container">
    <h2 class="error--text">The event could not be attended</h2>
    <p>{{ errorMessage }}</p>
  </section>

  <section v-else id="zoom-container" class="iframe-container"></section>
</template>

<script>
/* eslint-disable no-undef */
import { ZoomMtg } from "@zoomus/websdk";
import { getZoomSignature } from "../models/zoom";
import PermissionsMixin from "../components/mixins/permissions.mixin";

// Ensure zoom can run here
ZoomMtg.checkSystemRequirements();
// Link to local copy of node_modules files
ZoomMtg.setZoomJSLib(`${APP_URL}/vendor/zoom/lib`, "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

export default {
  name: "ZoomFrame",

  mixins: [PermissionsMixin],

  props: {
    meetingId: String,
    meetingRole: { type: Number, default: 0 },
    passcode: String,
    type: String,
    eventId: Number
  },

  data: () => ({
    errorMessage: null,
    meetConfig: null
  }),

  computed: {
    leaveUrl() {
      const suffix = this.meetingRole === 1 ? "review" : "view";
      const section = this.type === "Class" ? "classes" : "events";
      return `${APP_URL}/${section}/${this.eventId}/${suffix}`;
    }
  },

  async mounted() {
    if (!this.meetingId) {
      return (this.errorMessage = "Invalid Meeting Id");
    } else if (!this.activeUser) {
      return (this.errorMessage = "Invalid active user");
    }

    // Meeting config object
    this.meetConfig = {
      apiKey: ZOOM_API_KEY,
      apiSecret: ZOOM_API_SECRET,
      meetingNumber: this.meetingId,
      leaveUrl: this.leaveUrl,
      role: this.meetingRole
    };

    if (this.passcode) {
      this.meetConfig.passWord = this.passcode;
    }

    // Generate Signature function
    this.meetConfig.signature = await getZoomSignature({
      role: this.meetingRole,
      meetingNumber: this.meetingId
    });
    // join function
    this.initZoomMeeting();
  },

  methods: {
    initZoomMeeting() {
      // Init meetings
      ZoomMtg.init({
        leaveUrl: this.meetConfig.leaveUrl,
        isSupportAV: true,
        success: this.joinMeeting,
        error: this.onJoinError
      });
    },

    joinMeeting() {
      const meetingParams = {
        apiKey: this.meetConfig.apiKey,
        meetingNumber: this.meetConfig.meetingNumber,
        signature: this.meetConfig.signature,
        userName: this.activeUser.fullName,
        userEmail: this.activeUser.email,
        // handlers
        error: this.onJoinError,
        success: this.onJoinMeeting
      };

      if (this.meetConfig.passWord) {
        meetingParams.passWord = this.meetConfig.passWord;
      }

      ZoomMtg.join(meetingParams);
    },

    onJoinMeeting(res) {
      console.log("Joined meeting", res);
    },

    onInitError(err) {
      console.warn("Meeting init error", err);
    },

    onJoinError(err) {
      console.warn("Join fail", err);
    }
  }
};
</script>

<style lang="scss">
/* Buttons */
.ReactModal__Content.ReactModal__Content--after-open button,
#zmmtg-root button,
.chat-receiver-list__receiver,
.zm-modal-close,
.zm-btn {
  box-shadow: none;
  font-size: $sm;
  font-weight: normal;
  letter-spacing: initial;
  text-transform: initial;
  margin: initial;
}

#zmmtg-root {
  z-index: 15;

  ul[role="menu"] {
    @include flex-column;
    min-width: 250px;

    ::marker {
      content: "";
    }
  }

  li[role="presentation"] {
    height: initial;

    &,
    a {
      width: $parent;
    }
  }

  .chat-box__chat-textarea {
    height: $xxlg;
  }

  .chat-container__chat-control,
  .chat-receiver-list {
    height: $xxlg;
  }

  .chat-receiver-list {
    line-height: $xxlg;
  }

  .chat-receiver-list__receiver {
    height: $md;
    margin-top: 0;
    padding: $tiny $xxs;
  }

  .controls > * {
    width: $parent;
  }

  .controls button {
    @include h6;
    color: white;
  }

  .dropup {
    .dropdown-header[role="heading"] {
      background-color: $translucent;
      width: $parent;
    }

    .dropdown-menu[role="menu"] {
      visibility: hidden;
    }

    &.open .dropdown-menu[role="menu"] {
      @include slide-up-fade-in();
      visibility: visible;
    }
  }

  .dropdown {
    .dropdown-header[role="heading"] {
      background-color: $translucent;
      width: $parent;
    }

    .dropdown-menu[role="menu"] {
      visibility: hidden;
    }

    &.open .dropdown-menu[role="menu"] {
      @include slide-up-fade-in();
      visibility: visible;
    }
  }

  .dropdown-menu {
    &.security-option-menu__pop-menu li[role="presentation"] {
      width: calc(#{$parent} - 40px);
    }
  }

  .footer-button__button-label {
    font-size: 0.86rem;
    top: -9px;
  }
}
</style>
